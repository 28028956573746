
export default {
  name: 'login',
  head() {
    return {
      title: "User Login Kita Garuda | Pengguna Masuk Kita Garuda",
      meta: [
        { hid: 'og:description', property: 'og:description',content:  'Send us your feedback and enquiries online. | Silahkan mengirimkan masukan dan pertanyaan kamu secara online.' },
        { hid: 'og:title', property: 'og:title', content: "User Login Kita Garuda | Pengguna Masuk Kita Garuda" },
        { hid: 'og:type', property: 'og:type', content: 'website' },
      ]
    }
  },
  props: {
    windowWidth: Number,
    windowHeight: Number
  },
  data () {
    return {
      lang: this.$store.state.lang,
      loader: null,
      loading: false,
      alert: false,
      valid: false,
      alertMessage: '',
      email: '',
      resetEmaiSent: false,
      emailRules: [
        v => !!v || 'Email is required',
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email address must be valid'
      ],
      password: '',
      passwordRules: [
        v => !!v || 'Password is required'
        // v => (v && v.length >= 7) || 'Minimal 7 characters'
      ],
      forgotPw: false,
      show1: false,
      logoSrc: 'https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/timnas.png',
    }
  },
  computed: {
    getLanguage () {
      return this.$store.state.user.language || 'en'
    },
    authUser () {
      return this.$store.getters.authUser
    },
    getUser () {
      return this.$store.state.user
    },
  },
  mounted () {
    if (this.getUser && this.getUser.isLoggedIn) {
      this.$router.push({ path: '/', replace: true })
    }
  },
  methods: {
    async login () {
      this.loader = 'loading'
      try {
        const userCredential = await this.$fire.auth.signInWithEmailAndPassword(this.email, this.password)
        let userRef = this.$fire.firestore.collection('users').where('uid', '==', userCredential.user.uid)
        userRef.onSnapshot(docs => {
          if (!docs.empty) {
            docs.forEach(user => {
              this.userData = user.data()
              this.$store.commit('SetUserDetails', {
                uid: this.userData.uid,
                docId: user.id,
                language: this.userData.language,
                displayName: this.userData.name,
                aboutMe: this.userData.about_me,
                address: this.userData.address,
                fullName: this.userData.name,
                dob: this.userData.date_of_birth,
                sex: this.userData.sex,
                email: this.userData.email,
                gravatar: this.userData.gravatar,
                avatar: this.userData.avatar,
                isLoggedIn: true,
                isVerified: this.userData.email_verified,
                idVerified: this.userData.id_verified,
                emailVerified: this.userData.email_verified,
                phoneVerified: this.userData.phone_verified,
                accLevel: this.userData.acc_level,
                accType: this.userData.acc_type,
                accStatus: this.userData.status,
                accBalance: this.userData.acc_balance,
                memberSince: this.userData.created,
                favorites: this.userData.interests_favorites,
                myLeagues: this.userData.my_leagues,
                myTeams: this.userData.my_teams,
                myTeamsUpdated: this.userData.my_teams_last_update === undefined ? this.userData.my_teams_last_update : 0,
                kicktip: this.userData.kicktip,
                espl: this.userData.espl === undefined ? false : this.userData.espl,
                promo_code: this.userData.promo_code,
                platform: this.userData.platform,
                ellevate_user: this.userData.ellevate_user,
                date_subscription_started: this.userData.date_subscription_started,
                date_of_last_renewal: this.userData.date_of_last_renewal,
                payment_code: this.userData.payment_code
                // location: this.userData.location
              })
              this.$router.push('/myaccount')
            })
          } else {
            this.alert = true
            this.alertMessage = this.lang[this.getLanguage].NOT_FIND_USER
            this.loading = false
          }
          // ...
        }, err => {
          console.log(`Encountered error: ${err}`);
        })
      } catch (error) {
        this.alert = true
        this.alertMessage = error.message
        this.loader = null
        this.loading = false
      }
    },
    resetPwdLink () {
      // this.loader = 'loading'
      this.$fire.auth.sendPasswordResetEmail(this.email).then(() => {
        // Email sent.
        this.resetEmaiSent = true
        this.loader = null
        this.loading = false
      }).catch((error) => {
        // An error happened.
        console.log(error)
        this.alert = true
        this.alertMessage = error.message
        this.loader = null
        this.loading = false
      })
    },
    mailToOB () {
      window.open('mailto:contact@kitagaruda.id', '_blank')
    },
    enterPressed (e) {
      if (e.keyCode === 13) {
        // alert('Enter was pressed')
        this.login()
      }
    },
    inputChange (text) {
      this.alert = false
    }
  },
  watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]
    }
  }
}
