
import Login from '~/components/auth/Login'

export default {
  components: {
    Login,
  },
  data(){
    return{
      window: {
        width: 480,
        height: 480
      },
      scrolled: false,
      scrollY: 0,
    }
  },
  mounted  () {
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('scroll', this.handleScroll)
    this.handleResize()
  },

  destroyed () {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods:{
    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight

    },
    handleScroll () {
      this.scrollY = window.scrollY
      if (window.scrollY > 450) {
        this.scrolled = true
      } else {
        this.scrolled = false
      }
    }

  }
}
